import * as React from "react"
import "twin.macro"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/projects/Hero"
import FAQ from "../components/FAQ"
import CTAJoinUs from "../components/CTAJoinUs"

export default function AboutPage() {
  return (
    <Layout>
      <SEO title="Projects" />
      <Hero />

      <div tw="relative py-16 bg-white overflow-hidden">
        <div tw="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div tw="relative h-full text-lg max-w-prose mx-auto">
            <svg
              tw="absolute top-12 left-full transform translate-x-32"
              width="404"
              height="384"
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    tw="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="384"
                fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
              />
            </svg>
            <svg
              tw="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width="404"
              height="384"
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    tw="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="384"
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
            <svg
              tw="absolute bottom-12 left-full transform translate-x-32"
              width="404"
              height="384"
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    tw="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="404"
                height="384"
                fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
              />
            </svg>
          </div>
        </div>
        <div tw="relative px-4 sm:px-6 lg:px-8">
          <div tw="text-lg max-w-prose mx-auto mb-12">
            <p tw="text-xl text-gray-500 leading-8">
              MVFBLA provides our members with the opportunity to join
              chapter-wide initiatives known as Projects, an opportunity to
              implement the skills they have learned through competition and
              gain real-world experience in the business and tech world. We
              offer three distinct competitive projects, American Enterprise,
              Community Service, and Partnership with Business, as well as
              non-competitive State Projects.
            </p>
            <p tw="text-xl text-gray-500 leading-8 mt-2">
              Through these three projects, students have the opportunity to
              network directly with business professionals, create their own
              business plans and implement them in the real-world, give back to
              the community, gain internship opportunities, and more!
            </p>
          </div>
          <div tw="prose prose-lg text-gray-500 mx-auto">
            <h2>Committees:</h2>
            <p>
              Each of the three competitive projects (American Enterprise,
              Community Service, Partnership with Business) have a committee
              which is responsible for working directly with business
              professionals to develop and implement the various events. Project
              committees are a great way for members to apply their business
              skills in the real world, network with professionals, add
              experience to their resume, and show their dedication to FBLA.
              Chosen committee members will also have the opportunity to speak
              on behalf of MVFBLA at the State and National Leadership
              Competitions. If you are interested, look out for projects
              committee applications during September!
            </p>

            <h2>American Enterprise Project:</h2>
            <p>
              Every year for the American Enterprise project, MVFBLA promotes
              awareness around the American Enterprise system, organizing
              workshops and working with professionals to encourage financial
              literacy, entrepreneurship, career development, political literacy
              and more! MVFBLA holds a 4-week annual Shark Tank startup
              competition, during which business professionals lead workshops
              teaching the basics of creating a startup, including idea
              generation, marketing, and finance. At the final Shark Tank Pitch
              Day Event, teams pitch their business ideas developed through the
              workshops to a panel of real entrepreneurs, business
              professionals, and incubator mentors. In the past two years, the
              winning teams Seniors get Savvy and Edu K-8 were able to turn
              their business idea into a reality and are currently 501(c)(3)
              accredited nonprofits. In addition to Shark Tank, the American
              Enterprise committee has held job fairs, resume & mock interview
              workshops, financial literacy workshops, and more!
            </p>

            <h3>Past AE Reports:</h3>
            <ul>
              <li>
                2017-2018 Project Activate:
                https://drive.google.com/file/d/1CSP2f_lbhlNCUZ4c6yp5xLgT2ZFKq-GJ/view?usp=sharing
              </li>
              <li>
                2018-2019 Innovation Launchpad:
                https://drive.google.com/file/d/1k3VRaE1oLRQQ-7POUMgnidsRM1IcYNwh/view?usp=sharing
              </li>
            </ul>

            <h2>Community Service Project:</h2>
            <p>
              Each year for the Community Service Project, MVFBLA chooses aims
              to create a tangible change in specific social cause. In the past,
              the Community Service committee has chosen to focus on autism,
              homlesness, and substance abuse. Through the Community Service
              Project, members can not only gain volunteer hours, but also
              acquire many important skills such as networking and marketing.
              For the 2020-2021 school year, the Community Service Project is
              tackling environmental sustainability through unconventional
              approaches such as art. The project will including an upcycling
              program in partnership with MV Fashion club, speaker panels with
              businesses that practice sustainability, and raising awareness
              about climate change through art and social media.
            </p>
            <p>
              On March 10th, 2021, Monta Vista FBLA Community Service projects
              organized a successful tote bag fundraiser, hand making tote bags
              from old linens to raise money for the environmental defense fund,
              a large policy coalition responsible for creating green energy.
              “It’s a really cool way to increase sustainability because all
              funds go to environmental nonprofits and it encourages a healthy
              lifestyle” said Freshman Smriti Rangarajan, an FBLA member. “The
              fundraiser was a massive success, as initial estimates capped our
              total money raised at $200 but we ended up raising over 1000
              dollars!” said Cindy Zou. She is the chair of the Community
              Service Project from Monta Vista High School’s Future Business
              Leaders of America club, the committee that organized the
              fundraiser. The tote bags were created from linens that would’ve
              been in the landfill, because hotels cannot reuse old linens, so
              the committee not only saved linens from the landfill but raised
              money to save our planet from climate change. In order to create
              customizability to appeal to a broader segment of the student
              population, the committee utilized natural dyes to create several
              different product offerings. Sophomore Rohit Dayanand continues
              with the impacts of the fundraiser, "200+ tote bags sold isn't
              just a number, it's a lifestyle. What was important for us is
              where we raised our money from. For us, the tote bags that we sold
              to raise funds from directly encourages everyone to be more
              sustainable because it reduces the need for plastic bags. In fact,
              the New York Times estimates that each reusable bag saves on
              average 500 single-use plastic bags per year. That means that for
              the over two-hundred tote bags we directly sold to the public, on
              average we saved over 100,000 single-use plastic bags from
              polluting our oceans and landfills. On top of that, instead of
              purchasing reusable bags made from brand new materials and having
              them shipped from factories, we **hand-crafted** each of our tote
              bags from used hotel linens that would've also been in the
              landfill."The committee donated almost all of the money raised to
              the environmental defense fund, a Fremont Union School district
              approved charity that preserves acres of wilderness space and
              co-funds initiatives for green energy.
            </p>

            <h3>Past CS Reports:</h3>
            <ul>
              <li>
                2015-2016 Sunday Scholars (Education Disparity):
                https://drive.google.com/file/d/0B2epETtET0CkZ3pRVXJmc2RnUkE/view?usp=sharing
              </li>
              <li>
                2016-2017 The Heart Project (Art for
                Hospitalized/Underprivileged Children):
                https://drive.google.com/file/d/0B2epETtET0CkUVJaRVVRNXozUEk/view?usp=sharing
              </li>
              <li>
                2017-2018 The 22K Project (Homelessness):
                https://drive.google.com/file/d/1R7mnw06FdK6e_zTl5Nz861WmmWLhYSU7/view?usp=sharing
              </li>
              <li>
                2018-2019 Project Integrate (Autism):
                https://drive.google.com/file/d/1N95p-y0XWhmTETg1XohwL4_kQCNz4Fb3/view?usp=sharing
              </li>
            </ul>

            <h2>Partnership with Business Project:</h2>
            <p>
              Each year for the Partnership with Business Project, MVFBLA
              collaborates with a different business to engage in a mutually
              beneficial partnership. While members help the company by
              improving their marketing efforts, performing beta testing, and
              designing new features, the company allows us to gain exposure to
              the real business world and develop career oriented skills. In the
              past, MVFBLA has partnered with large companies like Facebook and
              smaller startups like Gnowbe and UCEazy.
            </p>
            <p>
              For the 2020-2021 school year, Monta Vista FBLA’s Partnership with
              Business committee, led by senior Yolanna Lu, partnered with the
              mental health and well-being app AumHum. Through this mutually
              beneficial partnership, FBLA members learned critical skills
              including market research, product development, marketing, and
              UI/UX design during workshops lead by AumHum professionals, and
              applied these skills to improve AumHum’s app. After analyzing the
              high school target demographic through surveys and focus groups,
              FBLA members participated in curating specific features to improve
              the app and better tailor it to high school students. Members were
              able to create tangible impact on the app, as their ideas led to
              the implementation of two brand new features: a
              mood-tracking/journaling feature and a relaxing sounds feature.
              Furthermore, the Partnership with Business Project gave members
              the opportunity to create and implement a marketing campaign for
              AumHum. From TikTok and Instagram to podcasts and digital video
              production, members were able to practice various marketing
              techniques and maintain a clear brand image over a range of
              popular platforms. In addition to teaching members valuable
              business skills, the Partnership with Business Project focused on
              engendering a deeper appreciation for mental health and
              mindfulness within the community. To that end, the Project
              committee collaborated with PTSA to organize school-wide
              meditation sessions and worked with Bring Change to Mind and
              nonprofit Essential Care and to make care packages for healthcare
              workers affected by the COVID-19 pandemic. In recognition of the
              impact this project had on the community, Monta Vista FBLA was
              honored with a Certificate of Recognition from Mayor Darcy Paul as
              well as Monta Vista’s Principal Ben Clausnitzer. In addition,
              AumHum’s CEO Aviral Pandey commended the committee’s efforts and
              selected diligent candidates to be long-term interns at their
              company.
            </p>

            <h3>Past PWB Reports:</h3>
            <ul>
              <li>
                2015-2016 Common Ground (UCEazy):
                https://drive.google.com/file/d/0B2epETtET0CkYnFzdjI0ekE0QVE/view?usp=sharing
              </li>
              <li>
                2016-2017 On the WayUp (WayUp):
                https://drive.google.com/file/d/0B2epETtET0CkNFdqeTZZenRXVDQ/view?usp=sharing
              </li>
              <li>
                2017-2018 Project Connect (Facebook):
                https://drive.google.com/file/d/1ud8bV2kWMvB41j4qvbUVo9-E2IwADB41/view?usp=sharing
              </li>
              <li>
                2018-2019 Project Innovate (Facebook):
                https://drive.google.com/file/d/1Jzyv1KFvBWUWCVzgniBDaUAQt2JeZ6Ew/view?usp=sharing
              </li>
              <li>
                2019-2020 Project Curate (Gnowbe):
                https://drive.google.com/file/d/1MnV6gCbzSHY8anqszPynKukvyBV8-n6C/view?usp=sharing
              </li>
            </ul>

            <h2>State Projects:</h2>
            <p>
              Unlike the aforementioned projects, State Projects are
              non-competitive and non-committee based. Instead State projects
              consist of two types, Individual and Chapter projects, that
              provide statewide recognition to those who complete specific sets
              of requirements. While the requirements for Chapter State Projects
              are met as a club, Individual State Projects require that
              individuals complete requirements at their own time. Please fill
              out the State Projects Interest Form or contact the Director of
              State Projects if you are interested in participating. For more
              information about Chapter and Individual state projects,{" "}
              <a href="https://www.cafbla.org/domain/93" target="_blank">
                check out the FBLA website
              </a>
              !
            </p>
          </div>
        </div>
      </div>

      <CTAJoinUs />
    </Layout>
  )
}
